.about-container {
  width: 100%;
  min-height: calc(100vh - 75px);
  /*background-color: #f5ce0c;
  background: rgb(91, 91, 91);
  background: linear-gradient(
    0deg,
    rgba(91, 91, 91, 1) 0%,
    rgba(175, 157, 70, 1) 39%,
    rgba(245, 206, 12, 1) 86%
  );
  */
  background-image: url(../images/aboutbackground.gif);
  background-size: cover;
  background-position: center;
  position: relative;
  z-index: 4;
}
.about-section {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-content: center;
  justify-content: center;
  align-items: center;
  background: linear-gradient(
    0deg,
    rgba(91, 91, 91, 1) 0%,
    rgb(175 157 70 / 95%) 39%,
    rgb(245 206 12) 86%
  );
}
.about-left {
  /*background-image: url(../images/protrait-1.jpeg);*/
  background-position: center;
  background-size: cover;
  width: 100%;
  height: 100%;
  min-height: calc(100vh - 75px);

  display: flex;
  align-items: center;
  justify-content: center;
  align-content: center;
  padding: 20px;
}
.about-left > .profilePic {
  height: 300px;
  width: 300px;
  border-radius: 20px;
}
.about-right {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 20px;
  color: white;
  font-size: 20px;
}

@media screen and (max-width: 600px) {
  .about-section {
    flex-wrap: wrap;
  }
  .about-left {
    min-height: 0;
  }
}

@import url("https://fonts.googleapis.com/css2?family=Arizonia&display=swap");

:root {
  --app-height: 100vh;
  --headerBottom: 0;
}

body {
  background-color: black;
}
.head-container {
  z-index: 2;
  min-height: 100vh;
  width: 100%;
  position: relative;
  /*
  -webkit-mask-image: url("../images/blur.svg");
  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;

  mask-image: url("../images/blur.svg");
  mask-position: center center;
  mask-repeat: no-repeat;
  mask-size: cover;
*/
  background: url(../images/video-fallback.jpg) 50% 50%;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.header-name {
  font-family: "Arizonia", cursive;
  color: #ffffff;
  font-size: 5em;
  margin-top: 0;
  margin-bottom: 0;
}

.head-container > video {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  display: block;
  z-index: -1;
  top: 0;
}

.profilePic {
  height: 150px;
  background-color: rgba(33, 32, 33, 0.628);
  width: 150px;
  border-radius: 50%;
  background-image: url(../images/protrait-1.jpeg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
  border-color: white;
  border-width: 5px;
  border-style: solid;
}

.head-text {
  width: 500px;
  color: white;
  font-size: 25px;
  text-align: center;
}

hr {
  border: 3px solid rgb(245, 206, 12);
  border-radius: 5px;
  width: 50%;
  background-color: rgb(245, 206, 12);
}

.head-footer {
  width: 100%;
  height: 75px;
  /*background-color: rgb(245 206 12 / 56%);*/
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex: 1;
  visibility: hidden;
}

.header-body {
  width: 100%;
  display: flex;
  flex: 2;

  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.header {
  width: 100%;
  height: 75px;
  background-color: rgb(245 206 12);
  z-index: 9999;
  position: fixed;
  bottom: 0;
  display: flex;
  align-content: flex-start;
  align-items: flex-end;
}

.header > ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  font-size: 25px;
  color: white;
  padding-left: 0;
  background-color: rgb(245 206 12 / 56%);
  height: 75px;
  margin-bottom: 0;
}

.header > ul > li {
  cursor: pointer;
  user-select: none; /* supported by Chrome and Opera */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none;
}
.head-footer > ul {
  list-style-type: none;
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  font-size: 25px;
  color: white;
  padding-left: 0;
  background-color: rgb(245 206 12);
  height: 75px;
  margin-bottom: 0;
}

.head-footer > ul > li {
  cursor: pointer;
}
.default-selected {
  color: darkblue;
}
.menu-selected {
  color: darkblue;

  animation: selected 1s forwards;
}

@keyframes selected {
  0% {
    transform: rotate(0deg);
  }

  20% {
    transform: rotate(20deg);
  }

  60% {
    transform: rotate(-20deg);
  }

  80% {
    transform: rotate(20deg);
  }

  100% {
    transform: rotate(0deg);
  }
}

@media screen and (max-width: 600px) {
  .header-name {
    font-size: 3em;
  }

  .head-text {
    width: 100%;
    color: white;
    font-size: 15px;
    text-align: center;
  }

  .head-footer > ul {
    font-size: 20px;
  }

  .header > ul {
    font-size: 20px;
  }

  .head-container {
    min-height: var(--app-height);
  }
}

@media screen and (max-height: 335px) {
  .profilePic {
    height: 60px;
    width: 60px;
  }

  .header-name {
    font-size: 2em;
  }

  .head-text {
    width: 100%;
    color: white;
    font-size: 15px;
    text-align: center;
  }

  .head-footer > ul {
    font-size: 20px;
  }

  .header > ul {
    font-size: 20px;
  }

  .head-container {
    min-height: var(--app-height);
  }
}

.body-container {
  min-height: 100vh;
  width: 100%;
  z-index: 3;

  -webkit-mask-image: url("../images/blur.svg");
  -webkit-mask-position: center center;
  -webkit-mask-repeat: no-repeat;
  -webkit-mask-size: cover;

  mask-image: url("../images/blur.svg");
  mask-position: center center;
  mask-repeat: no-repeat;
  mask-size: cover;

  background-color: rgb(189 142 22);
  background-image: url(../images/protrait-2.jpeg);
  background-attachment: fixed;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;

  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}
.voice-container {
  margin-top: 100px;
  width: 80%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 50px;
}
.recording-container {
  /* From https://css.glass */
  background: rgba(255, 255, 255, 0.28);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(6.4px);
  -webkit-backdrop-filter: blur(6.4px);
  border: 1px solid rgba(255, 255, 255, 0.38);
  padding-bottom: 20px;
  padding-top: 20px;
  margin-top: 5px;
  margin-bottom: 5px;
  padding-left: 10px;
  padding-right: 10px;

  display: flex;
  /*
  flex-direction: column;
  justify-content: center;
  align-items: center;
  */
}
.r-title {
  display: flex;
  flex: 2;

  flex-direction: column;
  color: white;
  justify-content: center;
  align-items: center;
  padding-bottom: 20px;
}

.r-body {
  flex: 3;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.r-body > audio {
  margin-bottom: 20px;
}

.r-title > h2 {
  color: rgb(245 206 12);
}

.body-container > video {
  object-fit: cover;
  width: 100%;
  height: 100vh;
  position: fixed;
  display: block;
  z-index: -1;
  top: 0;
  display: none;
}

@media screen and (max-width: 600px) {
  .body-container > video {
    object-fit: cover;
  }

  .recording-container {
    flex-direction: column;
  }
}
